import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { graphql, navigate } from "gatsby";
import { useLocation } from "@reach/router";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { isBrowser, checkNestedObj, getAllUrlParams } from "../../context/helpers";
import { populateBaseProducts, fillPrices } from "../../context/helpers_plp";
import { useTracking } from "../../context/Tracking";
import { DiscountContext } from "../../context/DiscountProvider";
import StoreContext from "../../context/store";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useCurrentCountryCode, usePricesByCollectionLocal } from "../../hooks/usePrices";
import { useCurrentLang } from "../../hooks/useLocalizedCartInfo";
import { useFiltersAndSorting, useOrderProducts } from "../../hooks/useFiltersAndSorting";
import useSEOSentenceToLinkConversion from "../../hooks/useSEOSentenceToLinkConversion";

import CollectionTemplate from "../../components/CollectionPage/CollectionTemplate";
import SEOContent from "../../components/SEOContent";
import BYOBCollection from "../../components/BYOBCollection";
import BackToTop from "../../components/al_components/BackToTop";
import LookingFor from "../../components/LookingFor";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";

import hpBanner from "../../images/hpBanner.jpg";
import SEOPLPLinks from "../../components/CollectionPage/SEOPLPLinks";

function SEOQuestion({ question, answer }) {
  const answerWithSEO = useSEOSentenceToLinkConversion(answer);
  return (
    <div className="content_seo_question">
      <p className="content_seo_question_q al_p al_p--bold">{question}</p>
      <p className="content_seo_question_a al_p">{answerWithSEO}</p>
    </div>
  );
}

function CollectionPage({ data, pageContext }) {
  const location = useLocation();
  const { discountInfo } = useContext(DiscountContext);
  const dict = useLocalizedSentenceDict();
  const { collection, content } = data;

  const countryCode = useCurrentCountryCode();

  const lang = useCurrentLang();
  const [productsInCollectionOriginal, setProductsInCollectionOriginal] = useState(() =>
    populateBaseProducts(data, pageContext)
  );

  useState(
    () => setProductsInCollectionOriginal(populateBaseProducts(data, pageContext)),
    [data, pageContext]
  );
  const {
    displayAB,
    setDisplayAB,
    displayTierDiscountBottomBanner,
    setDisplayTierDiscountBottomBanner,
  } = useContext(StoreContext);

  const isByobType = content?.type === "build-your-own-bundle";

  // Don't display AB on BYO pages when quantity_based_percent_off discount is active
  useEffect(() => {
    if (isByobType && discountInfo?.type === DISCOUNT_TYPES.QUANTITY_PERCENT) {
      setDisplayAB(false);
    } else {
      setDisplayAB(true);
    }
  }, [isByobType, discountInfo?.type, setDisplayAB]);

  const urlParams = useMemo(() => getAllUrlParams(), []);

  const isCollectionOfExclusions = useMemo(() => {
    // Returns true if >90% of products are excluded from sale
    const limit = 0.9;
    const totalProducts = productsInCollectionOriginal.length;
    const totalExcluded = productsInCollectionOriginal.reduce(
      (acc, { node: shopifyProduct }) =>
        shopifyProduct.tags.find((productTag) => discountInfo.exclusionTags.includes(productTag))
          ? acc + 1
          : acc,
      0
    );
    return totalExcluded / totalProducts >= limit;
  }, [discountInfo.exclusionTags, productsInCollectionOriginal]);

  // Display bottom AB and hide top AB on PLP after threshold when quantity_based_percent_off is active
  const hideAndShowAB = useCallback(() => {
    const threshold = 65;
    const approximateHeightQuantityBasedAB = 75;
    if (window.scrollY > threshold + approximateHeightQuantityBasedAB && displayAB) {
      setDisplayAB(false);
      setDisplayTierDiscountBottomBanner(true);
    } else if (window.scrollY <= threshold && !displayAB) {
      setDisplayAB(true);
      setDisplayTierDiscountBottomBanner(false);
    }
  }, [displayAB, setDisplayAB, setDisplayTierDiscountBottomBanner]);
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      discountInfo?.type === DISCOUNT_TYPES.QUANTITY_PERCENT &&
      !isByobType &&
      !isCollectionOfExclusions
    ) {
      window.addEventListener("scroll", hideAndShowAB);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", hideAndShowAB);
      };
    }
    if (isCollectionOfExclusions && displayTierDiscountBottomBanner) {
      setDisplayTierDiscountBottomBanner(false);
    }
  }, [
    hideAndShowAB,
    discountInfo?.type,
    isByobType,
    isCollectionOfExclusions,
    displayTierDiscountBottomBanner,
    setDisplayTierDiscountBottomBanner,
  ]);

  // Just to fill the cache with the latest prices in this collection
  const productsPrices = usePricesByCollectionLocal({
    productHandlesInCollection: productsInCollectionOriginal.map((p) => p.node.handle) || [],
    prices: data?.allShopifyPrices?.edges?.filter((p) => p?.node?.country === countryCode),
    productsTags: productsInCollectionOriginal.reduce(
      (acc, p) => ({ ...acc, [p.node.handle]: p.node.tags }),
      {}
    ),
    shouldUseDiscount: !isByobType,
  });

  const productsInCollection = useMemo(
    () => fillPrices(productsInCollectionOriginal, productsPrices),
    [productsInCollectionOriginal, productsPrices]
  );
  const [isDescriptionOpen, toggleDescription] = useState(false);
  const { trackViewCollectionPage } = useTracking();
  const { sortingType, setSortingType, activeFilters, setActiveFilters, products, filterStats } =
    useFiltersAndSorting(productsInCollection, { priceKey: "fullPrice" });
  const shouldOrderProducts = useOrderProducts(activeFilters, sortingType);

  useEffect(() => {
    const url = location.pathname;
    trackViewCollectionPage({
      collectionId: collection?.shopifyId,
      url,
      products: productsInCollection.map((p) => p.node),
      name: content?.title,
    });
  }, [collection, trackViewCollectionPage]);

  const getCollectionPageByType = () => {
    if (!content || !("type" in content)) return null;
    if (["classic", "seo", "co-creation", "image"].includes(content.type)) {
      return (
        <CollectionTemplate
          data={data}
          type={content.type}
          products={products}
          filterStats={filterStats}
          filters={activeFilters}
          sortingType={sortingType}
          onFilter={setActiveFilters}
          onSort={setSortingType}
          shouldOrderProducts={shouldOrderProducts}
        />
      );
    }
    if (isByobType) {
      return (
        <BYOBCollection
          data={data}
          products={products}
          filterStats={filterStats}
          filters={activeFilters}
          sortingType={sortingType}
          onFilter={setActiveFilters}
          onSort={setSortingType}
          shouldOrderProducts={shouldOrderProducts}
        />
      );
    }
    return isBrowser ? navigate("/404") : null;
  };

  const description = content?.description?.description || "";
  const descriptionWithSEO = useSEOSentenceToLinkConversion(description);

  const shouldRenderCollectionImage = ["co-creation", "image"].includes(content?.type);
  const collectionImageLink = useResponsiveWithHydrationFix(
    content?.images?.[0]?.mobileImage?.file?.url || "",
    content?.images?.[0]?.desktopImage?.file?.url || ""
  );

  let seoTitle = "";
  let isSeoTitle = false;
  if (content?.seoContent?.metaTitle) {
    seoTitle = content?.seoContent?.metaTitle;
    isSeoTitle = true;
  } else {
    seoTitle = content.title;
  }

  if (urlParams?.page) {
    seoTitle += ` - Page ${urlParams.page}`;
  }

  return (
    <div className="container_collection">
      <SEOContent
        type="collection"
        description={
          content && checkNestedObj(content, "seoContent", "description", "description")
            ? content.seoContent.description.description
            : description
        }
        title={seoTitle}
        handle={content ? content.handle : ""}
      />
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        meta={[
          {
            property: `og:image`,
            content: hpBanner,
          },
          {
            property: `og:image:width`,
            content: "1200",
          },
          {
            property: `og:image:height`,
            content: "630",
          },
        ]}
      >
        <link
          rel="canonical"
          href={`https://www.analuisa.com/collections/${collection?.handle}/`}
        />
      </Helmet>

      {shouldRenderCollectionImage && collectionImageLink && (
        <div className="container_collection_image">
          <img alt={content?.images?.[0]?.altText || ""} src={collectionImageLink} />
          {isSeoTitle ? (
            <>
              <h1 style={{ display: "none" }}>{seoTitle}</h1>
              <div className="collection_header_title">{content?.title && content.title}</div>
            </>
          ) : (
            <h1 className="collection_header_title">{content?.title && content.title}</h1>
          )}
        </div>
      )}

      {getCollectionPageByType()}

      {description && description.length > 10 ? (
        <div className="collection_content_description">
          {isDescriptionOpen ? (
            <p>
              {descriptionWithSEO}
              <span className="description_opened_read" onClick={() => toggleDescription(false)}>
                {dict.get("Read less")}
              </span>
            </p>
          ) : (
            <p className="content_description_closed" onClick={() => toggleDescription(true)}>
              {descriptionWithSEO}
              <span className="description_closed_more">
                <span className="description_closed_dot">...</span>
                <span className="description_closed_read">{dict.get("Read more")}</span>
              </span>
            </p>
          )}
        </div>
      ) : null}

      {lang === "en-US" && content && "seoQuestions" in content ? (
        <div className="collection_content_seo">
          {content.seoQuestions &&
            content.seoQuestions.map(
              (q, i) =>
                q.question.trim() !== "" &&
                q.question.trim().toLowerCase() !== "empty" && (
                  <SEOQuestion
                    key={`question_seo_${i}`}
                    question={q.question}
                    answer={q.answer.answer}
                  />
                )
            )}
        </div>
      ) : null}

      <LookingFor hasBorderTop noDesktopTop />

      <SEOPLPLinks
        seoCollectionLinks={pageContext.seoLinks || []}
        breadcrumb={content?.breadcrumb || null}
      />

      {/* BYOB page shows an giftbox element so don't show back to top */}
      {!isByobType && <BackToTop />}
    </div>
  );
}

export default withALErrorBoundary({
  name: "CollectionPage",
  priority: "P1",
})(CollectionPage);

export const query = graphql`
  query ($id: String!, $allProductsHandles: [String]!, $shopifyStore: String!, $lang: String!) {
    allShopifyPrices(filter: { handle: { in: $allProductsHandles } }) {
      edges {
        node {
          id
          country
          shopifyId
          handle
          variants {
            id
            priceV2: price {
              amount
              currencyCode
            }
            compareAtPriceV2: compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    collection: shopifyCollection(handle: { eq: $id }, storeName: { eq: $shopifyStore }) {
      id
      shopifyId
      handle
      title
    }
    allShopifyProduct(
      filter: { handle: { in: $allProductsHandles }, storeName: { eq: $shopifyStore } }
    ) {
      nodes {
        id
        title
        handle
        createdAt(fromNow: true)
        publishedAt
        productType
        tags
        shopifyId
        variants {
          availableForSale
          inventoryPolicy
          id
          title
          sku
          shopifyId
          selectedOptions {
            name
            value
          }
        }
        images {
          url
          altText
        }
      }
    }
    allContentfulProductPage(
      filter: { handle: { in: $allProductsHandles }, node_locale: { eq: $lang } }
    ) {
      edges {
        node {
          ...contentfulFields
        }
      }
    }
    content: contentfulCollectionPage(handle: { eq: $id }, node_locale: { eq: $lang }) {
      handle
      title
      type
      description {
        description
      }
      rowsPerPage
      hasCard
      breadcrumb {
        title
        levels {
          title
          link {
            link
          }
        }
      }
      cardsContainer {
        title
        cards {
          title
          subtitle
          discountCode
          backgroundColor
          textColor
          videoId
          link {
            title
            link {
              link
            }
          }
          images {
            altText
            desktopImage {
              file {
                url
              }
            }
            mobileImage {
              file {
                url
              }
            }
          }
        }
      }
      cards {
        title
        subtitle
        backgroundColor
        textColor
        videoId
        link {
          title
          link {
            link
          }
        }
      }
      preBundledProducts {
        bundleName
        tag {
          title
          backgroundColor
          textColor
        }
        productSize
        cpPosition
        products {
          handle
          variant
        }
        images {
          altText
          desktopImage {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
          }
          mobileImage {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
          }
        }
      }
      images {
        altText
        desktopImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          file {
            url
          }
        }
        mobileImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          file {
            url
          }
        }
      }
      seoContent {
        type
        metaTitle
        description {
          description
        }
      }
      seoQuestions {
        question
        answer {
          answer
        }
      }
      byobCollectionSettings {
        subtitle {
          subtitle
        }
        backgroundColor
        textAlign
        uspGroup {
          uspItems {
            uspTitle
            uspImage {
              desktopImage {
                gatsbyImageData
              }
              mobileImage {
                gatsbyImageData
              }
            }
          }
        }
        byobSize
        byobPercentOff
        canHaveOptionalItem
        byobPercentOffWithOptionalItemEnabled
      }
    }
  }
`;

// extracted by mini-css-extract-plugin
export var actions = "styles-module--actions--51e02";
export var active = "styles-module--active--052c7";
export var awesome = "styles-module--awesome--1e1e7";
export var bold = "styles-module--bold--53394";
export var button = "styles-module--button--beb9b";
export var center = "styles-module--center--d24f8";
export var h1 = "styles-module--h1--cabcf";
export var h1Bold = "styles-module--h1--bold--9475a";
export var h1Uc = "styles-module--h1--uc--d2126";
export var h2 = "styles-module--h2--d92bc";
export var h2Bold = "styles-module--h2--bold--49083";
export var h2Uc = "styles-module--h2--uc--766c0";
export var h3 = "styles-module--h3--142d6";
export var h3Bold = "styles-module--h3--bold--444a6";
export var h3Uc = "styles-module--h3--uc--7b121";
export var h4 = "styles-module--h4--8e193";
export var h4Bold = "styles-module--h4--bold--f19ac";
export var h4Uc = "styles-module--h4--uc--bece2";
export var incomplete = "styles-module--incomplete--26156";
export var linkUnderline = "styles-module--link--underline--230a3";
export var microtext = "styles-module--microtext--21b4a";
export var microtextBold = "styles-module--microtext--bold--c3c06";
export var microtextUc = "styles-module--microtext--uc--71f46";
export var strikethrough = "styles-module--strikethrough--3c9c3";
export var strikethroughLg = "styles-module--strikethrough--lg--075bb";
export var strikethroughSm = "styles-module--strikethrough--sm--77f00";
export var subtext = "styles-module--subtext--29541";
export var subtextBold = "styles-module--subtext--bold--0a1a7";
export var subtextUc = "styles-module--subtext--uc--cbec0";
export var subtitleStep = "styles-module--subtitle-step--bff1e";
export var subtitlesHeading = "styles-module--subtitles-heading--bddf2";
export var text = "styles-module--text--5aec3";
export var textBold = "styles-module--text--bold--b6cb4";
export var textUc = "styles-module--text--uc--0fa29";
export var titleL = "styles-module--title-l--ddbf6";
export var titleM = "styles-module--title-m--8e169";
export var titleS = "styles-module--title-s--f22de";
export var titleXl = "styles-module--title-xl--c118c";
export var titleXs = "styles-module--title-xs--36b2b";
export var titleXxs = "styles-module--title-xxs--46a68";
export var uc = "styles-module--uc--2a69e";
export var underline = "styles-module--underline--0e4f2";
import { PropTypes } from "prop-types";

import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import { useCurrentFormatCurrency } from "../../../hooks/usePrices";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";

import * as Styles from "./price/styles.module.scss";

function Price({ currentStepNumber, fullPrice, finalPrice, percentageOff }) {
  const dict = useLocalizedSentenceDict();
  const formatCurrency = useCurrentFormatCurrency();
  const { isMobile } = useDeviceDetect();
  const decimalize =
    finalPrice - Math.floor(finalPrice) === 0 ? finalPrice : finalPrice?.toFixed(2);

  return (
    <div className={`${Styles.priceContainer} ${currentStepNumber > 1 ? Styles.hasPrice : ""}`}>
      {!isMobile && <p className={`${Styles.subtotal}`}>{dict.get("Subtotal")}</p>}
      <div className={Styles.prices}>
        <p className={`${Styles.fullPrice} ${Styles.strikethrough}`}>{formatCurrency(fullPrice)}</p>
        <p className={Styles.finalPrice}>{formatCurrency(decimalize)}</p>
        <p className={`${Styles.discount}`}>
          ({percentageOff}% {dict.get("off")})
        </p>
      </div>
    </div>
  );
}

Price.propTypes = {
  currentStepNumber: PropTypes.number.isRequired,
  percentageOff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fullPrice: PropTypes.number.isRequired,
  finalPrice: PropTypes.number.isRequired,
};

export default withALErrorBoundary({
  name: "Price",
  priority: "P1",
})(Price);

// extracted by mini-css-extract-plugin
export var awesome = "style-module--awesome--97d88";
export var bold = "style-module--bold--d66ca";
export var center = "style-module--center--2a279";
export var closeBtn = "style-module--closeBtn--1693d";
export var container = "style-module--container--da634";
export var content = "style-module--content--c733d";
export var contentExpanded = "style-module--content--expanded--3b0cc";
export var h1 = "style-module--h1--f7326";
export var h1Bold = "style-module--h1--bold--dc721";
export var h1Uc = "style-module--h1--uc--6d1d1";
export var h2 = "style-module--h2--4d8be";
export var h2Bold = "style-module--h2--bold--fcf78";
export var h2Uc = "style-module--h2--uc--8a6cf";
export var h3 = "style-module--h3--b7494";
export var h3Bold = "style-module--h3--bold--bb4b9";
export var h3Uc = "style-module--h3--uc--5ce7f";
export var h4 = "style-module--h4--5c600";
export var h4Bold = "style-module--h4--bold--9e529";
export var h4Uc = "style-module--h4--uc--9152b";
export var info = "style-module--info--93d98";
export var infoMain = "style-module--info--main--ff679";
export var linkUnderline = "style-module--link--underline--3576c";
export var microtext = "style-module--microtext--2b94b";
export var microtextBold = "style-module--microtext--bold--5fd49";
export var microtextUc = "style-module--microtext--uc--938a8";
export var strikethrough = "style-module--strikethrough--0d6ef";
export var strikethroughLg = "style-module--strikethrough--lg--d5a4c";
export var strikethroughSm = "style-module--strikethrough--sm--d925d";
export var subtext = "style-module--subtext--9a0b2";
export var subtextBold = "style-module--subtext--bold--56bab";
export var subtextUc = "style-module--subtext--uc--3593d";
export var text = "style-module--text--68a6a";
export var textBold = "style-module--text--bold--2dfa0";
export var textUc = "style-module--text--uc--2f3c6";
export var titleL = "style-module--title-l--53e2d";
export var titleM = "style-module--title-m--e8f98";
export var titleS = "style-module--title-s--6a0e1";
export var titleXl = "style-module--title-xl--390ae";
export var titleXs = "style-module--title-xs--6583b";
export var titleXxs = "style-module--title-xxs--0e98f";
export var uc = "style-module--uc--38a4b";
export var underline = "style-module--underline--f89f0";
export var wrapper = "style-module--wrapper--15772";
export var wrapperExpanded = "style-module--wrapper--expanded--98a46";
import { useContext } from "react";
import { PropTypes } from "prop-types";

import { BYOBContext } from "../../context/BYOBundle";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { useSticky } from "../../hooks/useSticky";

import SelectedProducts from "./stepsContainerCommon/SelectedProducts";
import CustomBundleCTA from "./stepsContainerCommon/CustomBundleCTA";
import Subtitles from "./stepsContainerWithOptional/Subtitles";
import Price from "./stepsContainerWithOptional/Price";

import * as Styles from "./stepsContainerWithOptional/styles.module.scss";

function StepsContainerWithOptional({
  className,
  showSteps,
  byobDefaultPercentOff,
  byobPercentOffWithExtraOption,
}) {
  const {
    addCustomBundleToCart,
    removeProductToCustomBundle,
    fullPrice,
    finalPrice,
    finalPercentageOff,
    productList,
    byobSize,
    editMode,
    optionalItemEnabled,
    setOptionalItemEnabled,
  } = useContext(BYOBContext);

  const offsetSteps = useSticky();

  const currentStepNumber = productList.length + 1;
  const isSelectionComplete = productList.length === byobSize;

  return (
    <div
      className={`steps-wrapper-with-optional ${showSteps ? Styles.active : ""} ${className || ""}`}
      id="steps-wrapper"
      style={offsetSteps}
    >
      <div className={Styles.stepsDetails}>
        <div className={Styles.stepsDetails__leftContainer}>
          <Subtitles
            optionalItemEnabled={optionalItemEnabled}
            setOptionalItemEnabled={setOptionalItemEnabled}
            byobDefaultPercentOff={byobDefaultPercentOff}
            byobPercentOffWithExtraOption={byobPercentOffWithExtraOption}
          />
          {useResponsiveWithHydrationFix(
            currentStepNumber > 1 && (
              <Price
                currentStepNumber={currentStepNumber}
                fullPrice={fullPrice}
                finalPrice={finalPrice}
                percentageOff={finalPercentageOff}
              />
            ),
            null
          )}
        </div>
        <SelectedProducts
          byobSize={byobSize}
          productList={productList}
          removeProductToCustomBundle={removeProductToCustomBundle}
        />
        {useResponsiveWithHydrationFix(
          <CustomBundleCTA
            isBottomFixed
            addCustomBundleToCart={addCustomBundleToCart}
            currentStepNumber={currentStepNumber}
            isSelectionComplete={isSelectionComplete}
            byobSize={byobSize}
            editMode={editMode}
          />,
          <div className={Styles.stepsDetails__rightContainer}>
            <Price
              currentStepNumber={currentStepNumber}
              fullPrice={fullPrice}
              finalPrice={finalPrice}
              percentageOff={finalPercentageOff}
            />

            <CustomBundleCTA
              addCustomBundleToCart={addCustomBundleToCart}
              currentStepNumber={currentStepNumber}
              isSelectionComplete={isSelectionComplete}
              byobSize={byobSize}
              editMode={editMode}
            />
          </div>
        )}
      </div>
    </div>
  );
}

StepsContainerWithOptional.propTypes = {
  className: PropTypes.string,
  showSteps: PropTypes.bool.isRequired,
  byobDefaultPercentOff: PropTypes.number,
  byobPercentOffWithExtraOption: PropTypes.number,
};

export default StepsContainerWithOptional;

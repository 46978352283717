// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--1754c";
export var bold = "styles-module--bold--c1625";
export var center = "styles-module--center--ea7fc";
export var discount = "styles-module--discount--4f69a";
export var finalPrice = "styles-module--final-price--31f8f";
export var fullPrice = "styles-module--full-price--cbaba";
export var h1 = "styles-module--h1--9d164";
export var h1Bold = "styles-module--h1--bold--cb1b0";
export var h1Uc = "styles-module--h1--uc--a7409";
export var h2 = "styles-module--h2--881d1";
export var h2Bold = "styles-module--h2--bold--90709";
export var h2Uc = "styles-module--h2--uc--d0f65";
export var h3 = "styles-module--h3--fd0ed";
export var h3Bold = "styles-module--h3--bold--36bc3";
export var h3Uc = "styles-module--h3--uc--854c0";
export var h4 = "styles-module--h4--1d934";
export var h4Bold = "styles-module--h4--bold--3ab27";
export var h4Uc = "styles-module--h4--uc--989b7";
export var hasPrice = "styles-module--has-price--45594";
export var linkUnderline = "styles-module--link--underline--d1ee0";
export var microtext = "styles-module--microtext--4c1af";
export var microtextBold = "styles-module--microtext--bold--7b6fa";
export var microtextUc = "styles-module--microtext--uc--89934";
export var priceContainer = "styles-module--price-container--0bc53";
export var prices = "styles-module--prices--9ff8b";
export var strikethrough = "styles-module--strikethrough--03e29";
export var strikethroughLg = "styles-module--strikethrough--lg--c78ad";
export var strikethroughSm = "styles-module--strikethrough--sm--68cda";
export var subtext = "styles-module--subtext--8c403";
export var subtextBold = "styles-module--subtext--bold--43afb";
export var subtextUc = "styles-module--subtext--uc--65ed0";
export var subtotal = "styles-module--subtotal--cee53";
export var text = "styles-module--text--a8aff";
export var textBold = "styles-module--text--bold--6a04d";
export var textUc = "styles-module--text--uc--1466a";
export var titleL = "styles-module--title-l--11dd0";
export var titleM = "styles-module--title-m--b67cd";
export var titleS = "styles-module--title-s--98919";
export var titleXl = "styles-module--title-xl--22d9e";
export var titleXs = "styles-module--title-xs--91a08";
export var titleXxs = "styles-module--title-xxs--bab53";
export var uc = "styles-module--uc--84548";
export var underline = "styles-module--underline--7f173";
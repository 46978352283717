import { useContext, useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PropTypes } from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import {
  useCurrentCountryCode,
  useCurrentFormatCurrency,
  useFillProductsPrices,
} from "../../hooks/usePrices";
import { roundNumber } from "../../context/helpers";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useDeviceDetect from "../../hooks/useDeviceDetect";

import { useProductData2 } from "../../context/products";
import { BYOBContext } from "../../context/BYOBundle";

import "./preBundledProduct/styles.scss";
import { ALButton } from "../ALComponents";

function PreBundledProduct({ preBundledProduct }) {
  const { byobPercentageOff, addPreBundledProductToCart } = useContext(BYOBContext);
  const countryCode = useCurrentCountryCode();
  const { isMobile } = useDeviceDetect();
  const { data: productsData } = useProductData2(
    preBundledProduct.products.map((p) => p.handle),
    "long",
    countryCode
  );
  const productsWithPrices = useFillProductsPrices({
    products: productsData,
    shouldUseDiscount: false,
  });
  const formatCurrency = useCurrentFormatCurrency();
  const dict = useLocalizedSentenceDict();

  const isOutOfStock = productsData.some((product) =>
    product?.shopify.variants.some((v) => v.availableForSale === false)
  );

  const [preBundleFullPrice, setPreBundleFullPrice] = useState(null);
  const [preBundleFinalPrice, setpreBundleFinalPrice] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const fullPriceInCents = productsWithPrices.reduce(
      (acc, product) => acc + product.fullPrice * 100,
      0
    );
    setPreBundleFullPrice(fullPriceInCents / 100);
    setpreBundleFinalPrice((fullPriceInCents * (100 - byobPercentageOff)) / 100 / 100);
  }, [productsWithPrices, byobPercentageOff]);

  const getInitialOrderArray = (products) => {
    const newProductsArray = [];
    preBundledProduct.products.forEach((p) => {
      newProductsArray.push(products.find((prod) => prod.handle === p.handle));
    });
    return newProductsArray;
  };

  const handleAddToCart = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);

    // useProductData2 sorts the handles and we want the products in the same order as in Contentful
    const productsDataCopy = getInitialOrderArray(productsData);

    addPreBundledProductToCart(
      productsDataCopy,
      preBundledProduct,
      preBundleFullPrice,
      preBundleFinalPrice
    );
  };

  const tagBackgroundColor = !isOutOfStock
    ? preBundledProduct?.tag?.backgroundColor || "#000000"
    : "#dbd6ce";
  const tagTextColor = !isOutOfStock ? preBundledProduct?.tag?.textColor || "#FFF" : "#000000";
  const tagText = !isOutOfStock ? preBundledProduct?.tag?.title : dict.get("Out of stock");

  const defaultImage = preBundledProduct.images[0];
  const hoverImage = preBundledProduct.images[1];

  return (
    <>
      <div className="prebundled_image_container">
        {useResponsiveWithHydrationFix(
          <GatsbyImage
            image={getImage(defaultImage.mobileImage.gatsbyImageData)}
            alt={defaultImage.altText}
          />,
          <GatsbyImage
            className={`prebundled_image${hoverImage ? "--has-hover" : ""}`}
            image={getImage(defaultImage.desktopImage.gatsbyImageData)}
            alt={defaultImage.altText}
          />
        )}
        {!isMobile && hoverImage && (
          <GatsbyImage
            className="prebundled_image_hover"
            image={getImage(hoverImage.desktopImage.gatsbyImageData)}
            alt={hoverImage?.altText}
          />
        )}
        {tagText !== "null" && (
          <span
            className="microtext microtext--bold microtext--uc prebundled_tag"
            style={{
              backgroundColor: tagBackgroundColor,
              color: tagTextColor,
            }}
          >
            {tagText}
          </span>
        )}
      </div>
      <div className="prebundled_product__bundle">
        <p className="prebundled_product__bundle-name subtext subtext--bold">
          {preBundledProduct.bundleName}
        </p>
        {isOutOfStock && (
          <p className="prebundled_product_soldout subtext">{dict.get("Out of stock")}</p>
        )}
        {!isOutOfStock && (
          <div className="prebundled_product__bundle-prices">
            <p className="prebundled_product__bundle-prices-full subtext">
              {formatCurrency(roundNumber(preBundleFullPrice))}
            </p>
            <p className="prebundled_product__bundle-prices-final subtext">
              {formatCurrency(roundNumber(preBundleFinalPrice))}
            </p>
          </div>
        )}
      </div>
      {!isOutOfStock && (
        <ALButton
          variant="secondary"
          size="xsmall"
          onClick={handleAddToCart}
          className={`prebundled_product__button ${isDisabled ? "disabled" : ""}`}
        >
          {dict.get("Add to cart")}
        </ALButton>
      )}
    </>
  );
}

PreBundledProduct.propTypes = {
  preBundledProduct: PropTypes.shape({
    bundleName: PropTypes.string.isRequired,
    tag: PropTypes.shape({
      title: PropTypes.string,
      backgroundColor: PropTypes.string,
      textColor: PropTypes.string,
    }),
    images: PropTypes.array,
    products: PropTypes.array,
  }),
};

export default withALErrorBoundary({
  name: "PreBundledProduct",
  priority: "P2",
})(PreBundledProduct);

import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import "./collectionHeaderCard/CollectionHeaderCard.scss";

function CollectionHeaderCard({ content }) {
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();
  const bannerImage = useResponsiveWithHydrationFix(
    content?.images[0].mobileImage,
    content?.images[0].desktopImage
  );
  const isBYOBCollection = !!content?.byobCollectionSettings;
  const usps = content?.byobCollectionSettings?.uspGroup?.uspItems || [];
  const alignment = content?.byobCollectionSettings?.textAlign || "left";
  const bgColor = content?.byobCollectionSettings?.backgroundColor;
  const byobPercentOff = content?.byobCollectionSettings?.byobPercentOff;

  return (
    <section
      className="collection_header_card"
      style={bgColor ? { backgroundColor: bgColor } : null}
    >
      {bannerImage?.gatsbyImageData && bannerImage?.title !== "empty" ? (
        <GatsbyImage
          image={getImage(bannerImage?.gatsbyImageData)}
          className="collection_header_background"
          alt="background"
        />
      ) : null}
      <div
        className={`collection_header_card_container ${
          !isMobile && isBYOBCollection ? `collection_header_card_container--${alignment}` : ""
        }`}
      >
        <div className="collection_header_card_description">
          <h1 className="h2 h2--bold h2--uc collection_header_card_title">
            {dict.get(content?.title)}
          </h1>
          {content?.byobCollectionSettings?.subtitle && (
            <h2 className="text collection_header_card_subtitle">
              {dict.get(content.byobCollectionSettings.subtitle.subtitle, byobPercentOff)}
            </h2>
          )}
          {usps.length > 0 && (
            <div className="collection_header_usp_container">
              {usps.map((item, i) => (
                <span key={`collection-header-${i}`} className="collection_header_usp_content">
                  <GatsbyImage
                    className="collection_header_usp_image"
                    alt="Usp"
                    image={getImage(
                      isMobile
                        ? item?.uspImage?.mobileImage?.gatsbyImageData
                        : item?.uspImage?.desktopImage?.gatsbyImageData
                    )}
                  />
                  <p className="subtext collection_header_usp_title">{dict.get(item?.uspTitle)}</p>
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

CollectionHeaderCard.propTypes = {
  content: PropTypes.object,
};

export default CollectionHeaderCard;

import { PropTypes } from "prop-types";

import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import * as Styles from "./subtitles/styles.module.scss";

function Subtitles({ currentStepNumber, totalStepsNumber }) {
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();

  const getStep = (index) => {
    let step = "";
    switch (index) {
      case 2:
        step = dict.get("second");
        break;
      case 3:
        step = dict.get("third");
        break;
      default:
        step = dict.get("first");
    }
    return step;
  };

  return (
    <div>
      <h4 className={Styles.subtitlesHeading}>{dict.get("Your bundle")}</h4>
      {!isMobile && (
        <div>
          {Array.from({ length: totalStepsNumber }, (v, index) => (
            <p
              key={`byob-subtitle-${index}`}
              className={`${Styles.subtitleStep} ${
                currentStepNumber === index + 1 ? Styles.active : ""
              } ${currentStepNumber < index + 1 ? Styles.incomplete : ""}`}
            >
              {index + 1}.{` `}
              {index + 1 === totalStepsNumber
                ? dict.get("Your bundle is complete")
                : dict.get("Pick your {0} product", getStep(index + 1))}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

Subtitles.propTypes = {
  currentStepNumber: PropTypes.number.isRequired,
  totalStepsNumber: PropTypes.number.isRequired,
};

export default Subtitles;

import { PropTypes } from "prop-types";
import { useEffect, useRef } from "react";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import { ALButton } from "../../ALComponents";
import * as Styles from "./customBundleCTA/styles.module.scss";

function CustomBundleCTA({
  isBottomFixed,
  addCustomBundleToCart,
  currentStepNumber,
  isSelectionComplete,
  byobSize,
  editMode,
}) {
  const dict = useLocalizedSentenceDict();
  const buttonRef = useRef(null);
  useEffect(() => {
    buttonRef?.current?.style.setProperty(
      "--width",
      `${100 / (byobSize / (currentStepNumber - 1))}%`
    );
  }, [byobSize, currentStepNumber]);

  let ctaText = `${dict.get("Make your selections")} ${currentStepNumber}/${byobSize}`;
  if (isSelectionComplete) {
    if (editMode) {
      ctaText = dict.get("Update bundle");
    } else {
      ctaText = dict.get("Add to cart");
    }
  }

  return (
    <div
      className={`${Styles.buttonWrapper} ${isBottomFixed ? Styles.bottomButton : ""}`}
      ref={buttonRef}
    >
      <ALButton
        variant="secondary"
        size="large"
        onClick={addCustomBundleToCart}
        className={`${Styles.button} ${isSelectionComplete ? Styles.complete : Styles.disabled}`}
      >
        {ctaText}
      </ALButton>
    </div>
  );
}

CustomBundleCTA.propTypes = {
  isBottomFixed: PropTypes.bool,
  addCustomBundleToCart: PropTypes.func,
  currentStepNumber: PropTypes.number,
  isSelectionComplete: PropTypes.bool,
  byobSize: PropTypes.number,
  editMode: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "CustomBundleCTA",
  priority: "P2",
})(CustomBundleCTA);

// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--fd615";
export var bold = "styles-module--bold--6ec37";
export var center = "styles-module--center--88737";
export var discount = "styles-module--discount--44bd8";
export var finalPrice = "styles-module--final-price--480d1";
export var fullPrice = "styles-module--full-price--88ef5";
export var h1 = "styles-module--h1--283fe";
export var h1Bold = "styles-module--h1--bold--b751b";
export var h1Uc = "styles-module--h1--uc--297ea";
export var h2 = "styles-module--h2--316fd";
export var h2Bold = "styles-module--h2--bold--d6af5";
export var h2Uc = "styles-module--h2--uc--9605c";
export var h3 = "styles-module--h3--0c3af";
export var h3Bold = "styles-module--h3--bold--fd5bc";
export var h3Uc = "styles-module--h3--uc--10b68";
export var h4 = "styles-module--h4--73944";
export var h4Bold = "styles-module--h4--bold--a35af";
export var h4Uc = "styles-module--h4--uc--63abd";
export var hasPrice = "styles-module--has-price--41e3f";
export var linkUnderline = "styles-module--link--underline--a62a6";
export var microtext = "styles-module--microtext--40514";
export var microtextBold = "styles-module--microtext--bold--d0078";
export var microtextUc = "styles-module--microtext--uc--c3b92";
export var priceContainer = "styles-module--price-container--fecbc";
export var prices = "styles-module--prices--66bde";
export var strikethrough = "styles-module--strikethrough--6bbcc";
export var strikethroughLg = "styles-module--strikethrough--lg--139fe";
export var strikethroughSm = "styles-module--strikethrough--sm--ad04f";
export var subtext = "styles-module--subtext--6a563";
export var subtextBold = "styles-module--subtext--bold--2be42";
export var subtextUc = "styles-module--subtext--uc--84c5c";
export var subtotal = "styles-module--subtotal--9dd30";
export var text = "styles-module--text--bd9b8";
export var textBold = "styles-module--text--bold--ecb15";
export var textUc = "styles-module--text--uc--0a6e3";
export var titleL = "styles-module--title-l--64d65";
export var titleM = "styles-module--title-m--037dd";
export var titleS = "styles-module--title-s--d0bb7";
export var titleXl = "styles-module--title-xl--420b2";
export var titleXs = "styles-module--title-xs--3126c";
export var titleXxs = "styles-module--title-xxs--ac67e";
export var uc = "styles-module--uc--c84cb";
export var underline = "styles-module--underline--7e613";
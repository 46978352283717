import { Fragment, useContext, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { BYOBProductDetailPopupContext } from "../../context/BYOBProductDetailPopup";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { ALButton } from "../ALComponents";
import FiltersCollection from "../CollectionPage/FiltersCollection";
import ProductCollection from "../CollectionPage/ProductCollection";
import PreBundledProduct from "../buildYourOwnBundle/PreBundledProduct";
import { ComponentType } from "../../constants/ComponentType";
import "./byobCollectionProducts/BYOBCollectionProducts.scss";

function BYOBCollectionProducts({
  products,
  preBundledProducts,
  filterStats,
  filters,
  onFilter,
  collection,
  shouldOrderProducts,
  rowsPerPage,
  allShopifyPrices,
}) {
  const { isMobile } = useDeviceDetect();
  const { turnOnPopup } = useContext(BYOBProductDetailPopupContext);
  const filtersDekstopComp = (
    <FiltersCollection
      device="d"
      onFilter={onFilter}
      filterStats={filterStats}
      activeFilters={filters}
      className="byob_collections"
    />
  );
  const filtersDesktop = useResponsiveWithHydrationFix(null, filtersDekstopComp);
  const [page, setPage] = useState(1);
  const nbProductsByRow = isMobile ? 2 : 3;
  const isLastProductDisplayed = rowsPerPage * page * nbProductsByRow >= products.length;

  useEffect(() => setPage(1), [filters]);

  return (
    <div className="byob_collection_products">
      <div className="byob_collection_products_container">
        {filtersDesktop}

        <div className="byob_collection_products_list byob_collection_products_list--load-more">
          {products.map((product, i) => {
            const preBundledProduct =
              preBundledProducts.find((p) => p?.cpPosition === i + 1) || null;
            const cpPosition = i + 1;
            let prefixClassBundle = null;
            let bundleHandle = null;
            if (preBundledProduct) {
              bundleHandle = preBundledProduct?.bundleName.toLowerCase().split(" ").join("-");
              prefixClassBundle = `prebundled_product_collection_${preBundledProduct?.productSize}`;
            }
            const pastPreBundledProductCount = preBundledProducts.reduce(
              (acc, { bundleName, cpPosition }) =>
                bundleName !== "null" && cpPosition <= i + 1 ? acc + 1 : acc,
              0
            );
            const productRowIndex = Math.trunc(
              (i + pastPreBundledProductCount * 2) / nbProductsByRow
            );
            if (productRowIndex > rowsPerPage * page - 1) {
              return null;
            }
            return (
              <Fragment key={`coll_template_product_collection_${product?.node?.handle}`}>
                {preBundledProduct && preBundledProduct.bundleName !== "null" && (
                  <LazyLoad
                    once
                    classNamePrefix={`${bundleHandle && `pc_${bundleHandle}`} ${
                      shouldOrderProducts ? `order-${cpPosition}` : ""
                    } ${prefixClassBundle}`}
                    placeholder={<div style={{ height: "300px" }}>&nbsp;</div>}
                  >
                    <PreBundledProduct
                      preBundledProduct={preBundledProduct}
                      cpPosition={cpPosition}
                      shouldOrderProducts={shouldOrderProducts}
                    />
                  </LazyLoad>
                )}
                <ProductCollection
                  className="product_collection product_collection--left"
                  product={product}
                  collectionHandle={collection.handle}
                  collectionTitle={collection.title}
                  componentType={ComponentType.BYOB}
                  onClick={turnOnPopup}
                  orderProduct={cpPosition}
                  shouldOrderProducts={shouldOrderProducts}
                  prices={allShopifyPrices?.edges.filter(
                    (p) => p?.node?.handle === product?.node?.handle
                  )}
                />
              </Fragment>
            );
          })}
          {!isLastProductDisplayed && (
            <div className="byob_collection_products_list__load_more_button_container">
              <ALButton variant="secondary" onClick={() => setPage(page + 1)}>
                VIEW MORE
              </ALButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withALErrorBoundary({
  name: "BYOBCollectionProducts",
  priority: "P2",
})(BYOBCollectionProducts);

import { useState, useEffect } from "react";
import { useTracking } from "../../context/Tracking";

import ArrowRoundIconMd from "../icons/svgs/arrow-circle-md.svg";
import ArrowRoundIconLg from "../icons/svgs/arrow-circle-lg.svg";
import * as Styles from "./backToTop/styles.module.scss";

// Value of scrolled distance after which button is shown
const SCROLL_THRESHOLD = 150;

function BackToTop() {
  const { trackBackToTopClick } = useTracking();

  const [isVisible, setIsVisible] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    trackBackToTopClick(typeof window !== "undefined" ? window.location.href : "");
  };

  useEffect(() => {
    if (!window) {
      return null;
    }

    const handleScroll = () => {
      setIsVisible(window.scrollY > SCROLL_THRESHOLD);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <button type="button" onClick={goToTop} className={Styles.button}>
      <ArrowRoundIconMd width="35" stroke="#2D2927" className={Styles.arrowMd} />
      <ArrowRoundIconLg width="43" stroke="#2D2927" className={Styles.arrowLg} />
    </button>
  );
}

export default BackToTop;

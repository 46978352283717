import { Popup } from "../Popup";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { ALButton, ALLink } from "../ALComponents";

import "./popupPageUnavailableForReturns/PopupPageUnavailableForReturns.scss";

export default function PopupPageUnavailableForReturns() {
  const dict = useLocalizedSentenceDict();
  return (
    <Popup
      open
      lockScroll
      closeOnDocumentClick={false}
      closeOnEscape={false}
      className="page_unavailable_for_returns"
    >
      <h3 className="h3 h3--bold h3--uc">
        {dict.get("FYI: Your exchange credit is not eligible on this page!")}
      </h3>
      <p className="subtext">
        {dict.get(
          "Discounts and promotions including our Build Your Own Bundle and Build a Sale Bundle offers are not available when using an exchange credit."
        )}
      </p>
      <ALLink to="/collections/bestsellers">
        <ALButton>{dict.get("SHOP HERE INSTEAD")}</ALButton>
      </ALLink>
    </Popup>
  );
}

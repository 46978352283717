import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { usePriceInfo } from "../../../hooks/usePrices";
import { ALSkeleton } from "../../ALComponents";
import * as Styles from "./gitfBoxPrice/styles.module.scss";

function GiftBoxPrice({ productHandle, inline = true }) {
  const [{ data: prices }] = usePriceInfo({
    handles: [productHandle],
    tags: {},
    shouldUseDiscount: true,
  });

  const hasDiscount = prices?.full_price - prices?.final_price > 0;

  if (!prices) {
    return <ALSkeleton width="31px" height="18px" />;
  }

  const formattedFinalPrice = hasDiscount && prices.final_price ? prices.formattedFinalPrice : null;

  if (inline) {
    return (
      <div className={Styles.container}>
        {formattedFinalPrice ? (
          <>
            <span className="strikethrough">{prices.formattedFullPrice}</span>
            &nbsp;
            <span className="subtext--bold">{formattedFinalPrice}</span>
          </>
        ) : (
          <span className="subtext">{prices.formattedFullPrice}</span>
        )}
      </div>
    );
  }

  return (
    <div className={`${Styles.container} ${Styles.reverse}`}>
      {formattedFinalPrice ? (
        <>
          <span className="text text--bold">{formattedFinalPrice}</span>
          <span className="subtext strikethrough">{prices.formattedFullPrice}</span>
        </>
      ) : (
        <span className="text text--bold">{prices.formattedFullPrice}</span>
      )}
    </div>
  );
}

GiftBoxPrice.propTypes = {
  productHandle: PropTypes.string.isRequired,
  inline: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "GiftBoxPrice",
  priority: "P3",
})(GiftBoxPrice);

// extracted by mini-css-extract-plugin
export var card_collection = "collectionCardsStyles-module--card_collection--16c8d";
export var card_collection_climate_neutral = "collectionCardsStyles-module--card_collection_climate_neutral--e8a63";
export var card_collection_container = "collectionCardsStyles-module--card_collection_container--7a43a";
export var card_collection_content = "collectionCardsStyles-module--card_collection_content--be694";
export var card_collection_content_link = "collectionCardsStyles-module--card_collection_content_link--57c75";
export var card_collection_link = "collectionCardsStyles-module--card_collection_link--36bee";
export var card_collection_title = "collectionCardsStyles-module--card_collection_title--f3bda";
export var card_collection_title_multiline = "collectionCardsStyles-module--card_collection_title_multiline--7bc5e";
export var card_collection_video = "collectionCardsStyles-module--card_collection_video--7a111";
export var card_video_container = "collectionCardsStyles-module--card_video_container--aed4c";
export var card_video_container_vimeo = "collectionCardsStyles-module--card_video_container_vimeo--8fca2";
export var card_video_container_vimeo_video = "collectionCardsStyles-module--card_video_container_vimeo_video--f2835";
export var card_video_link = "collectionCardsStyles-module--card_video_link--ba37d";
export var card_video_link_container = "collectionCardsStyles-module--card_video_link_container--02a54";
export var has_links = "collectionCardsStyles-module--has_links--0ecee";
export var orderZero = "collectionCardsStyles-module--order-zero--f5f59";
export var video_container_none = "collectionCardsStyles-module--video_container_none--6810b";
import { useContext, useEffect, useRef, useState } from "react";
import { forceCheck } from "react-lazyload";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import StoreContext from "../context/store";
import { BYOBContext } from "../context/BYOBundle";
import { LoopReturnContext } from "../context/LoopReturnProvider";
import { isBrowser, getAllUrlParams } from "../context/helpers";
import { BYOBProductDetailPopupProvider } from "../context/BYOBProductDetailPopup";
import { HiddenElementsContext } from "../context/HiddenElements";

import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";
import useDeviceDetect from "../hooks/useDeviceDetect";
import CollectionHeaderCard from "./byobCollection/CollectionHeaderCard";
import MobileSimpleFilter from "./byobCollection/MobileSimpleFilter";
import BYOBCollectionProducts from "./byobCollection/BYOBCollectionProducts";
import CollectionSortLine from "./byobCollection/CollectionSortLine";
import GiftBoxUpsell from "./byobCollection/GiftBoxUpsell";
import StepsContainer from "./buildYourOwnBundle/StepsContainer";
import StepsContainerWithOptional from "./buildYourOwnBundle/StepsContainerWithOptional";
import PopupPageUnavailableForReturns from "./byobCollection/PopupPageUnavailableForReturns";

import { BYOB_DEFAULT_SIZE, BYOB_DEFAULT_PERCENTAGE_OFF } from "../constants/BYOBConfig";

import "./byobCollection/BYOBCollection.scss";
import "./CollectionPage/collection.scss";

function BYOBCollection({
  data,
  products,
  filterStats,
  filters,
  onFilter,
  sortingType,
  onSort,
  shouldOrderProducts,
}) {
  const { collection, content, allShopifyPrices } = data;
  const { setDisplayQuickAccess } = useContext(StoreContext);
  const {
    setByobSize,
    productList,
    loadBYOBCollection,
    setBYOBPercentageOff,
    optionalItemEnabled,
    setOptionalItemEnabled,
    setCanHaveOptionalItem,
  } = useContext(BYOBContext);
  const { isMobile } = useDeviceDetect();
  const { hide: hideThirdPartyFloatingElements, show: showThirdPartyFloatingElements } =
    useContext(HiddenElementsContext);
  const { isLoopReturnEnabled } = useContext(LoopReturnContext);

  const [showSteps, setShowSteps] = useState(false);
  const byobRef = useRef(null);
  const byobSettings = data?.content?.byobCollectionSettings || {};
  const canEnableOptionalItem = byobSettings?.canHaveOptionalItem || false;

  useEffect(() => {
    setCanHaveOptionalItem(canEnableOptionalItem);

    const allURLParams = getAllUrlParams();
    setOptionalItemEnabled(!!allURLParams.optionalItem);

    setDisplayQuickAccess(false);
    loadBYOBCollection(products);
    if (!isBrowser) {
      return null;
    }
    const topOffset = isMobile ? 200 : 350;
    let lastScroll = 0;

    const handleScroll = () => {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0 && lastScroll <= currentScroll) {
        lastScroll = currentScroll;
        if (window.pageYOffset > topOffset) {
          setShowSteps(true);
        }
      } else {
        lastScroll = currentScroll;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      setDisplayQuickAccess(true);
    };
  }, []);

  // Update BYOB size and percentage off when the page data is loaded
  useEffect(() => {
    if (optionalItemEnabled) {
      setByobSize(byobSettings?.byobSize ? byobSettings.byobSize + 1 : BYOB_DEFAULT_SIZE + 1);
      setBYOBPercentageOff(byobSettings?.byobPercentOffWithOptionalItemEnabled);
    } else {
      setByobSize(byobSettings?.byobSize ?? BYOB_DEFAULT_SIZE);
      setBYOBPercentageOff(BYOB_DEFAULT_PERCENTAGE_OFF);
    }
  }, [byobSettings, setByobSize, optionalItemEnabled, setBYOBPercentageOff]);

  // Hide the floating elements from the BYOB page
  // This is because we show the gift box upsell floating element
  useEffect(() => {
    if (isBrowser) {
      hideThirdPartyFloatingElements();
    }
    return () => {
      if (isBrowser) {
        showThirdPartyFloatingElements();
      }
    };
  }, [hideThirdPartyFloatingElements, showThirdPartyFloatingElements]);

  useEffect(() => {
    if (productList.length > 0 && !showSteps) {
      setShowSteps(true);
    }
  }, [productList.length]);

  useEffect(() => {
    // fix lazyload on products when refresh
    setTimeout(() => forceCheck(), 300);
  }, [products]);

  const mobileSimpleFilter = useResponsiveWithHydrationFix(
    <MobileSimpleFilter {...{ filterStats, filters, onFilter }} />,
    null
  );

  const stepsContainerMobile = useResponsiveWithHydrationFix(
    canEnableOptionalItem ? (
      <StepsContainerWithOptional
        showSteps={showSteps}
        byobDefaultPercentOff={byobSettings.byobPercentOff}
        byobPercentOffWithExtraOption={byobSettings.byobPercentOffWithOptionalItemEnabled}
      />
    ) : (
      <StepsContainer showSteps={showSteps} />
    ),
    null
  );

  const stepsContainerDesktop = useResponsiveWithHydrationFix(
    null,
    canEnableOptionalItem ? (
      <StepsContainerWithOptional
        showSteps={showSteps}
        byobDefaultPercentOff={byobSettings.byobPercentOff}
        byobPercentOffWithExtraOption={byobSettings.byobPercentOffWithOptionalItemEnabled}
      />
    ) : (
      <StepsContainer showSteps={showSteps} />
    )
  );

  return (
    <div className="byob_collection" ref={byobRef}>
      {isLoopReturnEnabled && <PopupPageUnavailableForReturns />}
      <CollectionHeaderCard content={content} />
      {stepsContainerMobile}
      {mobileSimpleFilter}
      <CollectionSortLine
        onSort={onSort}
        sortingType={sortingType}
        countProducts={products.length}
        collectionTitle={content.title}
        breadcrumbsLevels={content?.breadcrumb?.levels || null}
      />
      <GiftBoxUpsell />
      <BYOBProductDetailPopupProvider>
        <BYOBCollectionProducts
          {...{ products, filterStats, filters, onFilter, collection, allShopifyPrices }}
          preBundledProducts={data?.content?.preBundledProducts}
          shouldOrderProducts={shouldOrderProducts}
          rowsPerPage={content.rowsPerPage || 12}
        />
      </BYOBProductDetailPopupProvider>
      {stepsContainerDesktop}
    </div>
  );
}

export default withALErrorBoundary({
  name: "BYOBCollection",
  priority: "P2",
})(BYOBCollection);

import PropTypes from "prop-types";
import { Fragment } from "react";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import "./mobileSimpleFilter/MobileSimpleFilter.scss";

function MobileSimpleFilter({ filterStats, filters, onFilter }) {
  const filterProductTypes = filterStats.type || [];
  const handleButtonOnClick = (selectedfilterName) => () => {
    if (selectedfilterName === "all") {
      return onFilter([]);
    }
    if (!filters.includes(selectedfilterName)) {
      return onFilter([selectedfilterName]);
    }
  };
  return (
    filterProductTypes.length && (
      <div className="mobile_simple_filter">
        {[{ filter: "all", title: "All", count: 1 }, ...filterProductTypes].map(
          ({ filter: filterName, title }) => {
            const isChecked =
              filters.includes(filterName) || (filterName === "all" && !filters.length);
            return (
              <Fragment key={filterName}>
                <input
                  className="mobile_simple_filter_input"
                  type="radio"
                  onChange={handleButtonOnClick(filterName)}
                  value={filterName}
                  checked={isChecked}
                  id={filterName}
                />
                <label
                  className={`mobile_simple_filter_label ${
                    isChecked ? "mobile_simple_filter_label--checked" : ""
                  }`}
                  htmlFor={filterName}
                >
                  {title}
                </label>
              </Fragment>
            );
          }
        )}
      </div>
    )
  );
}

MobileSimpleFilter.propTypes = {
  filterStats: PropTypes.shape({
    type: PropTypes.arrayOf(
      PropTypes.shape({
        filter: PropTypes.string,
        title: PropTypes.string,
        count: PropTypes.number,
      })
    ),
  }),
  filters: PropTypes.arrayOf(PropTypes.string),
  onFilter: PropTypes.func,
};

export default withALErrorBoundary({
  name: "MobileSimpleFilter",
  priority: "P3",
})(MobileSimpleFilter);

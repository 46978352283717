import PropTypes from "prop-types";
import { ComponentType } from "../../constants/ComponentType";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import Breadcrumb from "../Breadcrumb";
import SortsCollection from "../CollectionPage/SortsCollection";
import "./collectionSortLine/CollectionSortLine.scss";

function CollectionSortLine({
  onSort,
  sortingType,
  countProducts,
  collectionTitle,
  breadcrumbsLevels,
}) {
  const dict = useLocalizedSentenceDict();
  const sortDropdown = useResponsiveWithHydrationFix(
    <SortsCollection
      className="collection_sort_text"
      onSort={onSort}
      sortingType={sortingType}
      componentType={ComponentType.BYOB}
    />,
    <SortsCollection onSort={onSort} sortingType={sortingType} />
  );

  return (
    <div className="col_sort_line">
      <div className="col_sort_line_content">
        <Breadcrumb
          collectionTitle={collectionTitle}
          productTitle={null}
          levels={breadcrumbsLevels}
        />
        <div className="col_sort_line_right">
          <p className="col_sort_count">
            {`${countProducts} ${dict.get(countProducts > 0 ? "products" : "product")}`}
          </p>
          {sortDropdown}
        </div>
      </div>
    </div>
  );
}

CollectionSortLine.propTypes = {
  onSort: PropTypes.func,
  sortingType: PropTypes.string,
  countProducts: PropTypes.number,
  collectionTitle: PropTypes.string,
};

export default withALErrorBoundary({
  name: "CollectionSortLine",
  priority: "P2",
})(CollectionSortLine);

import { PropTypes } from "prop-types";

import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import { ALButton } from "../../ALComponents";

import * as Styles from "./subtitles/styles.module.scss";

function Subtitles({
  optionalItemEnabled,
  setOptionalItemEnabled,
  byobDefaultPercentOff,
  byobPercentOffWithExtraOption,
}) {
  const dict = useLocalizedSentenceDict();

  return (
    <div>
      <h4 className={Styles.subtitlesHeading}>{dict.get("Your bundle")}</h4>

      <div className={Styles.actions}>
        <ALButton
          variant={optionalItemEnabled ? "secondary" : "primary"}
          size="xsmall"
          onClick={() => setOptionalItemEnabled(false)}
        >
          {dict.get(`3 for ${byobDefaultPercentOff}% off`)}
        </ALButton>
        <ALButton
          variant={optionalItemEnabled ? "primary" : "secondary"}
          size="xsmall"
          onClick={() => setOptionalItemEnabled(true)}
        >
          {dict.get(`4 for ${byobPercentOffWithExtraOption}% off`)}
        </ALButton>
      </div>
    </div>
  );
}

Subtitles.propTypes = {
  optionalItemEnabled: PropTypes.bool.isRequired,
  setOptionalItemEnabled: PropTypes.func.isRequired,
  byobDefaultPercentOff: PropTypes.number.isRequired,
  byobPercentOffWithExtraOption: PropTypes.number.isRequired,
};

export default Subtitles;

import { useContext } from "react";
import { PropTypes } from "prop-types";

import { BYOBContext } from "../../context/BYOBundle";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { useSticky } from "../../hooks/useSticky";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import SelectedProducts from "./stepsContainerCommon/SelectedProducts";
import CustomBundleCTA from "./stepsContainerCommon/CustomBundleCTA";
import Subtitles from "./stepsContainer/Subtitles";
import Price from "./stepsContainer/Price";

import * as Styles from "./stepsContainer/styles.module.scss";

function StepsContainer({ className, showSteps }) {
  const {
    addCustomBundleToCart,
    removeProductToCustomBundle,
    fullPrice,
    finalPrice,
    finalPercentageOff,
    productList,
    byobSize,
    editMode,
  } = useContext(BYOBContext);
  const offsetSteps = useSticky();

  const totalStepsNumber = byobSize + 1;
  const currentStepNumber = productList.length + 1;
  const isSelectionComplete = productList.length === byobSize;

  return (
    <div
      className={`steps-wrapper ${showSteps ? Styles.active : ""} ${className || ""}`}
      id="steps-wrapper"
      style={offsetSteps}
    >
      <div className={Styles.stepsDetails}>
        <div className={Styles.stepsDetails__leftContainer}>
          <Subtitles currentStepNumber={currentStepNumber} totalStepsNumber={totalStepsNumber} />
          {useResponsiveWithHydrationFix(
            <Price
              currentStepNumber={currentStepNumber}
              fullPrice={fullPrice}
              finalPrice={finalPrice}
              percentageOff={finalPercentageOff}
            />,
            null
          )}
        </div>
        <SelectedProducts
          byobSize={byobSize}
          productList={productList}
          removeProductToCustomBundle={removeProductToCustomBundle}
        />
        {useResponsiveWithHydrationFix(
          <CustomBundleCTA
            isBottomFixed
            addCustomBundleToCart={addCustomBundleToCart}
            currentStepNumber={currentStepNumber}
            isSelectionComplete={isSelectionComplete}
            byobSize={byobSize}
            editMode={editMode}
          />,
          <div className={Styles.stepsDetails__rightContainer}>
            <Price
              currentStepNumber={currentStepNumber}
              fullPrice={fullPrice}
              finalPrice={finalPrice}
              percentageOff={finalPercentageOff}
            />

            <CustomBundleCTA
              addCustomBundleToCart={addCustomBundleToCart}
              currentStepNumber={currentStepNumber}
              isSelectionComplete={isSelectionComplete}
              byobSize={byobSize}
              editMode={editMode}
            />
          </div>
        )}
      </div>
    </div>
  );
}

StepsContainer.propTypes = {
  className: PropTypes.string,
  showSteps: PropTypes.bool.isRequired,
};

export default withALErrorBoundary({
  name: "StepsContainer",
  priority: "P3",
})(StepsContainer);

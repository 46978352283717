import { Helmet } from "react-helmet";

function SEOContent({ type, description, title, handle }) {
  // TODO: social-meta-tags.liquid from old website
  let _url = null;
  let _title = null;
  if (type == "collection") {
    _url = `https://www.analuisa.com/${type.toLowerCase()}/${handle}`;
  }
  if (title) {
    _title = `${title} | Ana Luisa Jewelry`;
  }

  return (
    <Helmet>
      <title>{_title}</title>
      <meta name="description" content={description} />
      <meta property="og:site_name" content="Ana Luisa" data-template={type} />
      {_url ? <meta property="og:url" content={_url} /> : null}
      <meta property="og:title" content={_title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}

export default SEOContent;

import { PropTypes } from "prop-types";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { resizeShopifyImage } from "../../../context/helpers";

import AddIcon from "../../icons/svgs/add.svg";
import RemoveIcon from "../../icons/svgs/round-bordered-x.svg";

import * as Styles from "./selectedProducts/styles.module.scss";

function SelectedProducts({ byobSize, productList, removeProductToCustomBundle }) {
  const { isMobile } = useDeviceDetect();

  return (
    <div className={Styles.selectedProducts__container}>
      {Array.from({ length: byobSize }, (v, index) => {
        const product = productList[index];
        if (product) {
          return (
            <div className={Styles.product} key={`byob-selected-product-${index}`}>
              <img
                src={resizeShopifyImage(product.images[0].url, "x200")}
                alt={product.images[0].altText}
              />
              <button
                type="button"
                onClick={() =>
                  removeProductToCustomBundle(product.variants[product.variantIndex].id)
                }
                className={Styles.button}
              >
                <RemoveIcon width={isMobile ? 20 : 25} fill="#000000" stroke="black" />
              </button>
            </div>
          );
        }
        return (
          <div className={Styles.productSkeleton} key={`byob-product-skeleton-${index}`}>
            <AddIcon width="30" fill="#DBD6CE" />
          </div>
        );
      })}
    </div>
  );
}

SelectedProducts.propTypes = {
  byobSize: PropTypes.number.isRequired,
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  removeProductToCustomBundle: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "SelectedProducts",
  priority: "P2",
})(SelectedProducts);
